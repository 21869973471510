import Img1  from '../images/SafePoint+_4541_small.jpg';
import Img2  from '../images/SafePoint+_4546_small.jpg';
import Img3  from '../images/SafePoint+_4548_small.jpg';
import Img4  from '../images/SafePoint+_4551_small.jpg';
import Img5  from '../images/SafePoint+_4555_small.jpg';
import Img6  from '../images/SafePoint+_4559_small.jpg';
import Img7  from '../images/SafePoint+_4452_small.jpg';
import Img8  from '../images/SafePoint+_4455_small.jpg';
import Img9  from '../images/SafePoint+_4507_small.jpg';
import Img10  from '../images/SafePoint+_4512_small.jpg';
import Img11  from '../images/SafePoint+_4523_small.jpg';


console.log(Img1)

const images = [
        Img1,
        Img2,
        Img3,
        Img4,
        Img5,
        Img6,
        Img7,
        Img8,
        Img9,
        Img10,
        Img11,
];

$(() => {
        const $wrapper = $('.gallery__wrapper');

        for (const img of images) {
                $wrapper.append(`<a href="${img}" data-jbox-image="gallery1" class="gallery__image">
                        <img src="${img}" alt="">
                </a>`);
        }

        new jBox('Image');
});
